<template>
  <form @submit.prevent="createTeacher()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.username.$error }">
            <ion-label position="floating">Username</ion-label>
            <ion-input type="text" v-model="username"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.name.$error }">
            <ion-label position="floating">Name</ion-label>
            <ion-input type="text" v-model="name"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.email.$error }">
            <ion-label position="floating">Email</ion-label>
            <ion-input type="text" v-model="email"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.phone.$error }">
            <ion-label position="floating">Phone</ion-label>
            <ion-input type="text" v-model="phone"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.phone_alternate.$error }">
            <ion-label position="floating">Phone alternate</ion-label>
            <ion-input type="text" v-model="phone_alternate"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.dob.$error }">
            <ion-label position="floating">Date of birth</ion-label>
            <ion-datetime type="text" v-model="dob"></ion-datetime>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.gender_id.$error }">
            <ion-label position="floating">Gender</ion-label>
            <ion-select v-model="gender_id" :value="gender_id">
              <ion-select-option
                v-for="gender of genders"
                :key="gender.id"
                :value="gender.id"
              >
                {{ gender.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.language_id.$error }">
            <ion-label position="floating">Mother Tongue</ion-label>
            <ion-select v-model="language_id" :value="language_id">
              <ion-select-option
                v-for="language of languages"
                :key="language.id"
                :value="language.id"
              >
                {{ language.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.religion_id.$error }">
            <ion-label position="floating">Religion</ion-label>
            <ion-select v-model="religion_id" :value="religion_id">
              <ion-select-option
                v-for="religion of religions"
                :key="religion.id"
                :value="religion.id"
              >
                {{ religion.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.caste_id.$error }">
            <ion-label position="floating">Caste</ion-label>
            <ion-select v-model="caste_id" :value="caste_id">
              <ion-select-option
                v-for="caste of castes"
                :key="caste.id"
                :value="caste.id"
              >
                {{ caste.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.blood_group_id.$error }">
            <ion-label position="floating">BloodGroup</ion-label>
            <ion-select v-model="blood_group_id">
              <ion-select-option
                v-for="bloodGroup of bloodGroups"
                :key="bloodGroup.id"
                :value="bloodGroup.id"
              >
                {{ bloodGroup.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.address.$error }">
            <ion-label position="floating">Address</ion-label>
            <ion-textarea type="text" v-model="address"></ion-textarea>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.pincode.$error }">
            <ion-label position="floating">Pincode</ion-label>
            <ion-input type="text" v-model="pincode"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.fathers_name.$error }">
            <ion-label position="floating">Fathers name</ion-label>
            <ion-input type="text" v-model="fathers_name"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.mothers_name.$error }">
            <ion-label position="floating">Mothers name</ion-label>
            <ion-input type="text" v-model="mothers_name"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.pan_no.$error }">
            <ion-label position="floating">PAN No</ion-label>
            <ion-input type="text" v-model="pan_no"></ion-input>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.passport_no.$error }">
            <ion-label position="floating">Passport No</ion-label>
            <ion-input type="text" v-model="passport_no"></ion-input>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.voter_id.$error }">
            <ion-label position="floating">Voter ID</ion-label>
            <ion-input type="text" v-model="voter_id"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.aadhar_no.$error }">
            <ion-label position="floating">Aadhar No</ion-label>
            <ion-input type="text" v-model="aadhar_no"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.dl_no.$error }">
            <ion-label position="floating">Driving Licence</ion-label>
            <ion-input type="text" v-model="dl_no"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.password.$error }">
            <ion-label position="floating">Password</ion-label>
            <ion-input type="password" v-model="password"></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonIcon,
  IonButton,
  IonInput,
  IonTextarea,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import {
  required,
  alphaNum,
  integer,
  email,
  minLength,
  maxLength,
  helpers,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonIcon,
    IonButton,
    IonInput,
    IonTextarea,
    IonText,
  },
  data() {
    return {
      username: null,
      name: null,
      email: null,
      phone: null,
      phone_alternate: null,
      dob: null,
      gender_id: null,
      language_id: null,
      religion_id: null,
      caste_id: null,
      blood_group_id: null,
      address: null,
      pincode: null,
      fathers_name: null,
      mothers_name: null,
      pan_no: null,
      passport_no: null,
      voter_id: null,
      aadhar_no: null,
      dl_no: null,
      password: null,

      alertCircleOutline,
      alertCircleSharp,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      username: {
        required: helpers.withMessage("is required", required),
        alphaNum: helpers.withMessage("alphanumeric only", alphaNum),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      name: {
        required: helpers.withMessage("is required", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      email: {
        required: helpers.withMessage("is required", required),
        email: helpers.withMessage("must be a valid email", email),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      phone: {
        required: helpers.withMessage("is required", required),
        maxLength: helpers.withMessage("maximum 16 characters", maxLength(16)),
      },

      phone_alternate: {
        maxLength: helpers.withMessage("maximum 16 characters", maxLength(16)),
      },

      dob: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      gender_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      language_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      religion_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      caste_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      blood_group_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      address: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      pincode: {
        maxLength: helpers.withMessage("maximum 6 characters", maxLength(6)),
      },

      fathers_name: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      mothers_name: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      pan_no: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      passport_no: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      voter_id: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      aadhar_no: {
        mmaxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      dl_no: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      password: {
        minLength: helpers.withMessage("minimum 8 characters", minLength(8)),
        maxLength: helpers.withMessage("maximum 64 characters", maxLength(64)),
      },
    };
  },

  computed: {
    genders() {
      return this.$store.getters["attribute/genders"];
    },
    bloodGroups() {
      return this.$store.getters["attribute/bloodGroups"];
    },
    languages() {
      return this.$store.getters["attribute/languages"];
    },
    religions() {
      return this.$store.getters["attribute/religions"];
    },
    castes() {
      return this.$store.getters["attribute/castes"];
    },
  },

  created() {
    this.fetchBloodGroups();
    this.fetchGenders();
    this.fetchLanguages();
    this.fetchReligions();
    this.fetchCastes();
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createTeacher() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }
      let data = {
        username: this.username,
        name: this.name,
        email: this.email,
        phone: this.phone,
        phone_alternate: this.phone_alternate,
        dob: this.dob,
        gender_id: this.gender_id,
        language_id: this.language_id,
        religion_id: this.religion_id,
        caste_id: this.caste_id,
        blood_group_id: this.blood_group_id,
        address: this.address,
        pincode: this.pincode,
        fathers_name: this.fathers_name,
        mothers_name: this.mothers_name,
        pan_no: this.pan_no,
        passport_no: this.passport_no,
        voter_id: this.voter_id,
        aadhar_no: this.aadhar_no,
        dl_no: this.dl_no,
        password: this.password,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("teacher/createResource", data);
        spinner.dismiss();

        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetchGenders() {
      await this.$store.dispatch("attribute/genders");
    },

    async fetchBloodGroups() {
      await this.$store.dispatch("attribute/bloodGroups");
    },

    async fetchLanguages() {
      await this.$store.dispatch("attribute/languages");
    },

    async fetchReligions() {
      await this.$store.dispatch("attribute/religions");
    },

    async fetchCastes() {
      await this.$store.dispatch("attribute/castes");
    },
  },
};
</script>