<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
  <template v-slot:search-bar>
    <ion-toolbar>
      <ion-searchbar placeholder="Search by name" v-model="search_string" @ionChange="search"></ion-searchbar>
    </ion-toolbar>
  </template>
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-teacher :resources="resources"></list-teacher>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setCreateModalState(true)">
        <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-modal :is-open="createModalState" @didDismiss="setCreateModalState(false)">
      <base-modal title="Create Teacher" @closeModal="setCreateModalState(false)">
        <create-teacher @closeModal="setCreateModalState(false)"></create-teacher>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import CreateTeacher from "../../Components/User/Teacher/CreateTeacher";
import ListTeacher from "../../Components/User/Teacher/ListTeacher";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonIcon,
  IonFabButton,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonSearchbar,
} from "@ionic/vue";

import { addOutline } from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    BaseModal,
    CreateTeacher,
    ListTeacher,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonIcon,
    IonFabButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    IonSearchbar,
  },

  data() {
    return {
      title: "Teachers",
      backLink: "user",
      reloadable: true,

      search_string: null,

      createModalState: false,

      addOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["teacher/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = false) {
      await this.$store.dispatch("teacher/resources", { refresh: refresh, search_string: this.search_string });
    },
    loadData(event) {
      this.fetch();
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("teacher/resources", { refresh: true, search_string: this.search_string });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },

    search(){
      this.refresh()
    }
  },

  created() {
    this.fetch();
  },
};
</script>

<style scoped>
</style>
